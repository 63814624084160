<template>
  <svg @click='svgClick' @dblclick='svgEnd'>
    <circle v-for='(item,index) in circles' :key='index' :cx='item.x' :cy='item.y' :fill='storkeColor'
            :stroke='storkeColor'
            r='5' stroke-width='2'></circle>
    <template v-for='(item,index) in polygons'>

      <polygon
        :key="'polygon'+index" :points='item.path'
        :style="{fill:'transparent',stroke:item.storkeColor||storkeColor,strokeWidth:2}"
        @keyup='e=>deletePolygon(e,index)'
        @mousedown.self='e=>polygonDragStart(e,index)' @mousemove.self='e=>polygonMoveAction(e,index)'
        @mouseup.self='polygonMove={}' @mouseleave.self='polygonMove={}'/>
      <circle v-for='(child,i) in item.circles' :key="'child'+i+index" :cx='child.x' :cy='child.y'
              :fill='item.storkeColor||storkeColor'
              :stroke="'#fff'"
              class='circle'
              r='5' stroke-width='3'
              @mousedown.stop='e=>polygonDragStart(e,index,i)' @mouseleave.stop='polygonMove={}'
              @mousemove.stop='e=>polygonMoveAction(e,index,i)' @mouseup.stop='polygonMove={}'>

      </circle>
      <image v-if='!disabled' :key='"img"+index' :x='item.circles[0].x-25' :y='item.circles[0].y-25' height='16px'
             width='16px' xlink:href='@/assets/img/close.png' @click='deletePolygon(index)'/>
    </template>

  </svg>
</template>

<script>
export default {
  name: 'draw',
  props: {
    storkeColor: {
      type: String,
      default: '#1791fc',
    },
    drawCode: {
      type: String,
    },
    camera: {
      type: Number,
      default: 0,
    },
    showPolygons: {
      type: Array,
      default: () => new Array(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      circles: [],
      polygons: [],
      edit: false,
      polygonMove: {
        x: 0,
        y: 0,
      },
    };
  },
  watch: {
    camera() {
      this.polygons = this.showPolygons.filter(v => v.camera === this.camera);
      console.log(this.showPolygons);
    },
    showPolygons: {
      deep: true,
      handler(e) {
        console.log(e, 2);
      },
    },
  },
  created() {
    this.initPolygons()
  },
  methods: {
    initPolygons() {
      console.log(this.showPolygons);
      this.polygons = this.showPolygons.filter(v => v.camera === this.camera);
    },
    svgClick(e) {
      if (!this.edit) return;
      this.circles.push({x: e.offsetX, y: e.offsetY});
    },
    svgEnd() {
      // console.log(1);
      if (this.circles.length > 2) {
        this.circles.splice(this.circles.length - 1, 1);
        this.polygons.push({
          path: this.setPolygon(this.circles),
          circles: this.circles,
          drawCode: this.drawCode,
          storkeColor: this.storkeColor,
          camera: this.camera,
        });
        this.circles = [];
        this.edit = false;
        this.$emit('drawDown', this.polygons);
      }
    },
    setPolygon(arr) {
      let path = '';
      arr.forEach(v => {
        path += ` ${v.x},${v.y}`;
      });
      return path;
    },
    polygonDragStart(e) {
      this.polygonMove.x = e.offsetX;
      this.polygonMove.y = e.offsetY;
    },
    polygonMoveAction(e, index, i) {
      if (this.disabled) return;
      setTimeout(() => {
        if (!this.polygonMove.x) return;
        const dX = e.offsetX - this.polygonMove.x;
        const dY = e.offsetY - this.polygonMove.y;
        // return;
        const circle = this.polygons[index].circles;
        if (circle[i]) {
          circle[i].x += dX;
          circle[i].y += dY;
        } else {
          circle.forEach(v => {
            v.x += dX;
            v.y += dY;
          });
        }

        let path = this.setPolygon(circle);
        this.$set(this.polygons[index], 'path', path);
        this.$set(this.polygons[index], 'circles', circle);
        this.$set(this, 'polygonMove', {x: e.offsetX, y: e.offsetY});
        this.$emit('drawMove', this.polygons);
      }, 0);
    },
    deletePolygon(index) {
      this.$emit('deletePolygon', {polygon: this.polygons[index]});
      this.polygons.splice(index, 1);

    },
  },
};
</script>

<style scoped>

svg {
  height: 100%;
  width: 100%;
}

.circle {
  cursor: move;
}

polygon, image {
  cursor: pointer;
}

image {
  z-index: 1;
}
</style>
